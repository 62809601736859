(function (window, $, RecurringTimer, app) {
    var delay = window.delay,
        parseFloat = window.parseFloat;
    
    function Slider() {
        var _this = this;
        
        var getSlides = function (slider) {
            if (typeof slider === 'string') {
                return $('#' + slider + ' .slider\\.slides');
            }
            
            return slider.find('.slider\\.slides');
        };
        
        this.init = function ($slider) {
            var $slides = getSlides($slider);
            var type = $slider.data('type');
            
            var options = {
                autoplay: true,
                infinite: true,
                pauseOnHover: true,
                arrows: true,
                prevArrow: '.slider\\.control.\\--prev',
                nextArrow: '.slider\\.control.\\--next',
                speed: app.ANIMATION_DURATION,
                autoplaySpeed: 7500
            };
            
            $slides.slick(options);
        };
        
        this.goto = function (slider, $dot, data) {
            getSlides(slider).slick('slickGoTo', data.slide);
        };
        
        this.next = function (slider) {
            getSlides(slider).slick('slickNext');
        };
        
        this.prev = function (slider) {
            getSlides(slider).slick('slickPrev');
        };
    }
    
    app.registerModule('Slider', new Slider());
})(window, jQuery, RecurringTimer, app);

